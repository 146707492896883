import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'
import * as ROUTES from '~/constants/routes'
import useMessages from '~/hooks/useMessages'

import CityMappingUpdateForm from '../components/CityMappingUpdateForm'
import { useCityMappingDetail, useCityMappingUpdate, useCityMappingDelete } from '../hooks'

function CityMappingUpdateContainer () {
  const { id } = useParams()
  const snackbar = useSnackbar()
  const navigate = useNavigate()
  const messages = useMessages()

  const cityMappingUpdate = useCityMappingUpdate(id)
  const cityMappingDetail = useCityMappingDetail(id)
  const cityMappingDelete = useCityMappingDelete(id)

  const pageLoading = cityMappingUpdate.isLoading || cityMappingDetail.isLoading

  const title = pageLoading ? 'City Mapping' : cityMappingDetail.detail.city

  const onDelete = () =>
    cityMappingDelete.delete()
      .then(() => snackbar({ message: messages.DELETE_SUCCESS }))
      .then(() => navigate(ROUTES.CITY_MAPPING_LIST_PATH))

  const handleSubmit = formValues =>
    cityMappingUpdate.update(formValues)
      .then(() => cityMappingDetail.getDetail())
      .then(() => snackbar({ message: messages.UPDATE_SUCCESS }))
      .then(() => navigate(ROUTES.CITY_MAPPING_LIST_PATH))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={NAV.CITY_MAPPING}
      activeNav={NAV.CITY_MAPPING}
      isLoading={pageLoading}
      breadcrumbs={breadcrumbs}
    >
      <CityMappingUpdateForm
        title={title}
        pageTitleLoading={cityMappingDetail.isLoading}
        initialValues={cityMappingDetail.detail}
        isLoading={cityMappingUpdate.isLoading || cityMappingDelete.isLoading || cityMappingDetail.isLoading}
        handleDelete={onDelete}
        onSubmit={handleSubmit}
      />
    </DashboardLayout>
  )
}

export default CityMappingUpdateContainer
