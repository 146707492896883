import React from 'react'

import SearchField from '~/components/Fields/SearchField'
import * as API from '~/constants/api'

function CarrierSearchField (props) {
  return (
    <SearchField
      api={API.SHIPMENT_INTEGRATIONS}
      label="Carrier"
      name="carrier"
      {...props}
    />
  )
}

export default CarrierSearchField
