import React from 'react'

import { OPTIONS as BASE_OPTIONS } from '~/components/Fields/AllocationStatusField'
import MultiSelectField from '~/components/Fields/MultiSelectField'

function AllocationStatusField (props) {
  return (
    <MultiSelectField
      name="allocationStatus"
      label="Allocation status"
      options={BASE_OPTIONS}
      {...props}
    />
  )
}

export default AllocationStatusField
