import React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types'
import Divider from '@mui/material/Divider'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import { path, prop } from 'ramda'

import TextLabelLoader from '~/components/TextLabelLoader'
import BooleanStatus from '~/components/BooleanStatus'
import Timezone from '~/components/Timezone'
import CardHeader from '~/components/Cards/CardHeader'
import BooleanLabel from '~/components/BooleanLabel'

import ReceivingType from './ReceivingType'
import PickingStrategy from './PickingStrategy'
import ReceivingStrategy from './ReceivingStrategy'

function WarehouseContent ({ warehouse }) {
  const name = prop('name', warehouse)
  const label = prop('label', warehouse)
  const phone = prop('phoneNumber', warehouse)
  const address = prop('address', warehouse)
  const country = path(['country', 'name'], warehouse)
  const city = prop('city', warehouse)
  const zipcode = prop('zipcode', warehouse)
  const latitude = prop('latitude', warehouse)
  const longitude = prop('longitude', warehouse)
  const status = prop('status', warehouse)
  const timezone = prop('timezone', warehouse)
  const firstName = prop('contactFirstName', warehouse)
  const lastName = prop('contactLastName', warehouse)
  const email = prop('contactEmail', warehouse)
  const pickingStrategy = prop('pickingStrategy', warehouse)
  const receivingStrategy = prop('receivingStrategy', warehouse)
  const receivingType = prop('receivingType', warehouse)
  const isDefault = prop('isDefault', warehouse)
  const partialPackingAllowed = prop('partialPackingAllowed', warehouse)
  const partialPickingAllowed = prop('partialPickingAllowed', warehouse)
  const scanUniqueLocation = prop('scanUniqueLocation', warehouse)
  const scanUniqueContainer = prop('scanUniqueContainer', warehouse)
  const region = prop('region', warehouse)

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} lg={8} xs={12}>
        <Box mb={3}>
          <Card>
            <CardHeader title="Information" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Warehouse name">{name}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Label">{label}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Phone number">{phone}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Address">{address}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Country">{country}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="City">{city}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Zipcode">{zipcode}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Region">{region}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Longitude">{longitude}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={6}>
                  <TextLabelLoader label="Latitude">{latitude}</TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>

        <Box>
          <Card>
            <CardHeader title="Primary contact" />
            <Divider />
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="First name">{firstName}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12} lg={6}>
                  <TextLabelLoader label="Last name">{lastName}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Email">{email}</TextLabelLoader>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextLabelLoader label="Phone number">{phone}</TextLabelLoader>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Grid>

      <Grid item={true} lg={4} xs={12}>
        <Card>
          <CardHeader title="Settings" />
          <Divider />
          <CardContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <TextLabelLoader label="Status">
                  <BooleanStatus value={status} />
                </TextLabelLoader>
              </Grid>

              <Grid item={true} xs={12}>
                <TextLabelLoader label="Picking strategy">
                  <PickingStrategy value={pickingStrategy} />
                </TextLabelLoader>
              </Grid>

              <Grid item={true} xs={12}>
                <TextLabelLoader label="Receiving strategy">
                  <ReceivingStrategy value={receivingStrategy} />
                </TextLabelLoader>
              </Grid>

              <Grid item={true} xs={12}>
                <TextLabelLoader label="Timezone">
                  <Typography variant="subtitle1">
                    <Timezone value={timezone} />
                  </Typography>
                </TextLabelLoader>
              </Grid>

              <Grid item={true} xs={12}>
                <TextLabelLoader label="Receiving type">
                  <ReceivingType value={receivingType} />
                </TextLabelLoader>
              </Grid>

              <Grid item={true} xs={12}>
                <BooleanLabel label="Default Warehouse">
                  {isDefault}
                </BooleanLabel>
              </Grid>

              <Grid item={true} xs={12}>
                <BooleanLabel label="Allow partial packing">
                  {partialPackingAllowed}
                </BooleanLabel>
              </Grid>

              <Grid item={true} xs={12}>
                <BooleanLabel label="Allow partial picking">
                  {partialPickingAllowed}
                </BooleanLabel>
              </Grid>

              <Grid item={true} xs={12}>
                <BooleanLabel label="Scan unique location">
                  {scanUniqueLocation}
                </BooleanLabel>
              </Grid>

              <Grid item={true} xs={12}>
                <BooleanLabel label="Scan unique container">
                  {scanUniqueContainer}
                </BooleanLabel>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

WarehouseContent.propTypes = {
  warehouse: PropTypes.object.isRequired
}

export default WarehouseContent
