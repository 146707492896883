import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'storfox-snackbar'
import { useTranslation } from 'react-i18next'

import { DashboardLayout } from '~/components/Layouts'
import * as ROUTES from '~/constants/routes'
import * as NAV from '~/constants/nav-titles'

import CityMappingCreateForm from '../components/CityMappingCreateForm'
import { useCityMappingCreate } from '../hooks'

function CityMappingCreateContainer () {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const snackbar = useSnackbar()
  const cityMappingCreate = useCityMappingCreate()

  const title = 'New City Mapping'

  const message = t('Successfully created')
  const handleSubmit = formValues =>
    cityMappingCreate.create(formValues)
      .then(() => navigate(ROUTES.CITY_MAPPING_LIST_PATH))
      .then(() => snackbar({ message }))

  const breadcrumbs = { title }

  return (
    <DashboardLayout
      title={title}
      isLoading={cityMappingCreate.isLoading}
      activeNav={NAV.CITY_MAPPING}
      breadcrumbs={breadcrumbs}
    >
      <CityMappingCreateForm
        title={title}
        onSubmit={handleSubmit}
        isLoading={cityMappingCreate.isLoading}
      />
    </DashboardLayout>
  )
}

export default CityMappingCreateContainer
