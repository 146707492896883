import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'

import { CardHeader } from '~/components/Cards'
import TextField from '~/components/Fields/TextField'
import { Button, DiscardButton, SaveButton } from '~/components/Buttons'
import PageTitle from '~/components/PageTitle'
import Container, { Content, Header } from '~/components/Container'
import { withForm } from '~/components/Form'
import * as NAV from '~/constants/nav-titles'

import CarrierSearchField from '../components/CarrierSearchField'
import AliasesField from '../components/AliasesField'

function CityMappingUpdateForm ({ title, isLoading, form, handleDelete }) {
  const { handleSubmit } = form

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Header>
          <PageTitle
            pageTitle={title}
            parentTitle={NAV.CITY_MAPPING}
            rightButton={(
              <>
                <DiscardButton disabled={isLoading} />
                <Button
                  disabled={isLoading}
                  variant="contained"
                  sx={{ background: '#e12d2d' }}
                  onClick={handleDelete}
                >
                  Delete
                </Button>
                <SaveButton disabled={isLoading} />
              </>
            )}
          />
        </Header>

        <Content>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Card>
                <CardHeader title="Details" />
                <Divider />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} md={6} xs={12}>
                      <TextField
                        name="city"
                        label="City"
                        required={true}
                      />
                    </Grid>
                    <Grid item={true} md={6} xs={12}>
                      <CarrierSearchField />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <AliasesField label="Aliases" name="aliases" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Content>
      </Container>
    </form>
  )
}

CityMappingUpdateForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object,
  handleDelete: PropTypes.func,
  isLoading: PropTypes.bool.isRequired
}

export default withForm(CityMappingUpdateForm)
