import React from 'react'
import { Ordering } from 'storfox-filter'

import { ROLE_COLUMNS as tableColumns } from './CityMappingTable'

import { FILTER_NAME as name } from '../constants'

export const cityMappingOrderingOptions = {
  name,
  tableColumns
}

function CityMappingOrderingForm (props) {
  return (
    <Ordering {...props} />
  )
}

export default CityMappingOrderingForm
