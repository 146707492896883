import { pick, prop } from 'ramda'
import { DEFAULT_SEARCH_PARAMS } from 'storfox-route-hooks'

import { escapeAtob, orderingToSnackCase } from '~/utils'

export const SaleOrderFilterSerializer = params => {
  const defaultParams = pick(DEFAULT_SEARCH_PARAMS, params)
  const status = prop('status', params)
  const deliveryCity = prop('deliveryCity', params)
  const allocationStatusEscape = escapeAtob(prop('allocationStatus', params)) || []
  const allocationStatuses = allocationStatusEscape.map((item) => item.value).join(',')
  const fulfillmentStatus = prop('fulfillmentStatus', params)
  const shipmentStatus = prop('shipmentStatus', params)
  const paymentStatus = prop('paymentStatus', params)
  const totalPriceStart = prop('totalPriceStart', params)
  const totalPriceEnd = prop('totalPriceEnd', params)
  const lineItemsCountStart = prop('lineItemsCountStart', params)
  const lineItemsCountEnd = prop('lineItemsCountEnd', params)

  const customerGuid = escapeAtob(prop('customer', params))
  const channelGuids = escapeAtob(prop('channels', params))
  const companyGuids = escapeAtob(prop('companies', params))
  const warehouseGuids = escapeAtob(prop('warehouses', params))
  const paymentType = escapeAtob(prop('paymentType', params))
  const deliveryCountry = escapeAtob(prop('deliveryCountry', params))
  const deliveryMethod = escapeAtob(prop('deliveryMethod', params))
  const createdAtStart = prop('createdAtStart', params)
  const createdAtEnd = prop('createdAtEnd', params)
  const updatedAtStart = prop('updatedAtStart', params)
  const updatedAtEnd = prop('updatedAtEnd', params)

  return orderingToSnackCase({
    ...defaultParams,
    status,
    allocationStatuses,
    fulfillmentStatus,
    shipmentStatus,
    paymentStatus,
    deliveryCountry,
    deliveryCity,
    deliveryMethod,
    customerGuid,
    channelGuids,
    companyGuids,
    totalPriceStart,
    totalPriceEnd,
    createdAtStart,
    createdAtEnd,
    updatedAtStart,
    updatedAtEnd,
    warehouseGuids,
    lineItemsCountStart,
    lineItemsCountEnd,
    paymentType
  })
}
