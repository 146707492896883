import React from 'react'
import { useOrdering } from 'storfox-filter'

import { DashboardLayout } from '~/components/Layouts'
import * as NAV from '~/constants/nav-titles'

import CityMappingTable from '../components/CityMappingTable'
import CityMappingOrderingForm, { cityMappingOrderingOptions } from '../components/CityMappingOrderingForm'
import { useCityMappingList } from '../hooks'

function CityMappingListContainer () {
  const cityMappingList = useCityMappingList()

  const ordering = useOrdering(cityMappingOrderingOptions)

  const breadcrumbs = { title: NAV.CITY_MAPPING }

  return (
    <DashboardLayout
      title={NAV.CITY_MAPPING}
      activeNav={NAV.CITY_MAPPING}
      breadcrumbs={breadcrumbs}
    >
      <CityMappingOrderingForm {...ordering} />

      <CityMappingTable
        list={cityMappingList}
        ordering={ordering}
        onListRefetch={cityMappingList.getListByParams}
      />
    </DashboardLayout>
  )
}

export default CityMappingListContainer
